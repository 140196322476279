import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/shared/layoutParallax"
import SEO from "../../components/SEO/SEOPage"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import {
  wrapper,
  color,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Breadcrumbs from "../../components/shared/Breadcrumbs"
import GetWindowWidth from "../../components/shared/GetWindowWidth"

const WrapperDesktop = styled("div")`
  ${wrapper}
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  .top-wrap {
    position: absolute;
    top: 25%;
    left: 32px;
    right: 32px;
  }
  h1 {
    margin: 0 0 20px;
    font-weight: 800;
    font-size: 64px;
    line-height: 124.6%;
  }
  .subtitle {
    font-size: 16px;
    line-height: 24px;
    white-space: pre-wrap;
  }
  .gatsby-image-wrapper {
    border-radius: 24px;
    max-height: 448px;
    &::after {
      content: "";
      width: 100%;
      height: 100%;
      background: ${color.transparentBlack};
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-radius: 24px;
    }
  }
  img {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    .gatsby-image-wrapper {
      max-height: 100%;
    }
    h1 {
      font-size: 26px;
      line-height: 32px;
      margin: 0 0 16px;
    }
  }
  @media (max-width: ${breakpoints.xs}) {
    .top-wrap {
      top: 30%;
      left: 16px;
      right: 16px;
    }
    h1 {
      font-size: 22px;
      line-height: 30px;
      margin: 0;
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  padding: 0;
`

const BottomBanner = styled("div")`
  background: linear-gradient(
    128.46deg,
    rgba(169, 0, 0, 0.4) 25.23%,
    rgba(120, 0, 0, 0.1) 88.32%
  );
  padding: 32px 0;
  text-align: center;
  span {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    color: ${color.gold};
  }
`

const BottomColumn = styled("div")`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .col {
    flex-basis: 48%;
    background: ${color.transparentWhite1};
    border-radius: 24px;
    padding: 16px;
    margin-bottom: 32px;
    margin-right: 1%;
  }
  @media (max-width: ${breakpoints.sm}) {
    .col {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`

const ChildPagesWrap = styled("div")`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 32px 0;
  .item {
    flex-basis: 23%;
    background: ${color.transparentWhite1};
    border-radius: 24px;
    padding: 24px 20px;
    margin: 0 2% 2% 0;
    &:nth-of-type(4n) {
      margin-right: 0;
    }
    .gatsby-image-wrapper {
      border-radius: 16px;
    }
    .button {
      width: 100%;
    }
  }
  .title {
    color: ${color.gold};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    max-height: 46px;
    overflow: hidden;
    margin: 16px 0;
  }
  .description {
    font-size: 14px;
    line-height: 24px;
    max-height: 72px;
    overflow: hidden;
    margin-bottom: 14px;
  }
  @media (max-width: ${breakpoints.md}) {
    .item {
      flex-basis: 49%;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .item {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }
`

const ChildPostsWrap = styled("div")`
  ${wrapper}
  .item {
    display: block;
    margin-bottom: 48px;
    text-decoration: none;
    color: ${color.white};
  }
  .title {
    h2 {
      color: ${color.gold};
      font-weight: 700;
      font-size: 40px;
      line-height: 42px;
      margin-bottom: 16px;
    }
  }
  .img-wrap {
    width: 100%;
    max-width: 50%;
    float: left;
    margin: 0 32px 30px 0;
    .gatsby-image-wrapper {
      border-radius: 24px;
    }
  }
  .description {
    ol,
    ul {
      padding: 0;
    }
    h3 {
      margin: 16px 0;
      font-weight: 700;
      font-size: 26px;
      line-height: 32px;
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    .img-wrap {
      max-width: 100%;
      float: none;
      margin: 24px 0;
    }
    .description {
      display: flex;
      flex-direction: column-reverse;
      ol,
      ul {
        padding-left: 15px;
      }
    }
  }
`

const PageTemplate = ({ data, pageContext }) => {
  const windowWidth = GetWindowWidth()
  const isDesktop = windowWidth > 600
  const currentPage = data.wordpressPage
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const page_subtitle = currentPage.acf.top_subtitle
  const featuredMedia = currentPage.featured_media
  const metaTitle =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_title
      ? currentPage.yoast_meta.yoast_wpseo_title
      : currentPage.title + "- " + process.env.OWNER
  const metaDesc =
    currentPage.yoast_meta && currentPage.yoast_meta.yoast_wpseo_metadesc
      ? currentPage.yoast_meta.yoast_wpseo_metadesc
      : ""
  const content_field_one = currentPage.acf.content_field_01
  const content_field_two = currentPage.acf.content_field_02
  const content_field_tree = currentPage.acf.content_field_03
  const content_field_four = currentPage.acf.content_field_04
  const childPages = data.allWordpressPage
  const childPosts = data.allWordpressPost
  const cta_footer = currentPage.acf.cta_footer
  const socialImage = currentPage.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentPage.path}
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <WrapperDesktop>
        {featuredMedia &&
        featuredMedia.localFile &&
        featuredMedia.localFile.childImageSharp &&
        featuredMedia.localFile.childImageSharp.fluid &&
        isDesktop ? (
          <FeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <Img
              fluid={featuredMedia.localFile.childImageSharp.fluid}
              alt={
                currentPage.featured_media.alt_text
                  ? currentPage.featured_media.alt_text
                  : page_title
              }
              fadeIn={false}
              loading="eager"
            />
            <div className="top-wrap">
              <h1
                className="h1 rich-design"
                dangerouslySetInnerHTML={{ __html: page_title }}
              />
              {page_subtitle && (
                <span
                  className="subtitle"
                  dangerouslySetInnerHTML={{ __html: page_subtitle }}
                />
              )}
            </div>
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper>
            <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
            <h1 dangerouslySetInnerHTML={{ __html: page_title }} />
            {page_subtitle && (
              <span dangerouslySetInnerHTML={{ __html: page_subtitle }} />
            )}
          </NoFeaturedWrapper>
        )}

        <ChildPagesWrap>
          {childPages.edges.map((item, index) => {
            const localFile = item.node.featured_media?.localFile || {}
            return (
              <div className="item" key={index + Math.random() * 100}>
                <div>
                  {localFile.childImageSharp && (
                    <Img
                      fluid={localFile.childImageSharp.fluid}
                      alt={
                        item.node.featured_media.alt_text
                          ? item.node.featured_media.alt_text
                          : "holidaypalace"
                      }
                      fadeIn={false}
                      loading="eager"
                    />
                  )}
                </div>
                <div className="title">
                  <span dangerouslySetInnerHTML={{ __html: item.node.title }} />
                </div>
                <div className="description">
                  <span
                    dangerouslySetInnerHTML={{ __html: item.node.content }}
                  />
                </div>
                <div>
                  <Link
                    to={item.node.path}
                    className="button transparent-button"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            )
          })}
        </ChildPagesWrap>

        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentPage.content }} />
          <BottomColumn>
            {content_field_one && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_one }} />
              </div>
            )}
            {content_field_two && (
              <div className="col">
                <span dangerouslySetInnerHTML={{ __html: content_field_two }} />
              </div>
            )}
            {content_field_tree && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_tree }}
                />
              </div>
            )}
            {content_field_four && (
              <div className="col">
                <span
                  dangerouslySetInnerHTML={{ __html: content_field_four }}
                />
              </div>
            )}
          </BottomColumn>
        </ContentWrapper>
      </WrapperDesktop>

      <ChildPostsWrap>
        {childPosts.edges.map((item, index) => {
          const localFile = item.node.featured_media?.localFile || {}
          return (
            <Link
              to={"/" + item.node.slug + "/"}
              key={index + Math.random() * 100}
              className="item"
            >
              <div className="title">
                <h2 dangerouslySetInnerHTML={{ __html: item.node.title }} />
              </div>
              <div className="description">
                <div className="img-wrap">
                  {localFile.childImageSharp && (
                    <Img
                      fluid={localFile.childImageSharp.fluid}
                      alt={
                        item.node.featured_media.alt_text
                          ? item.node.featured_media.alt_text
                          : "holidaypalace"
                      }
                      fadeIn={false}
                      loading="eager"
                    />
                  )}
                </div>
                <span dangerouslySetInnerHTML={{ __html: item.node.content }} />
              </div>
            </Link>
          )
        })}
      </ChildPostsWrap>

      {cta_footer && (
        <BottomBanner>
          <WrapperDesktop>
            <span dangerouslySetInnerHTML={{ __html: cta_footer }} />
          </WrapperDesktop>
        </BottomBanner>
      )}
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      title
      content
      date(formatString: "MMMM DD, YYYY")
      path
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        page_template
        top_subtitle
        cta_footer
        content_field_01
        content_field_02
        content_field_03
        content_field_04
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    allWordpressPage(
      filter: { parent_element: { wordpress_id: { eq: 1774 } } }
    ) {
      edges {
        node {
          path
          title
          content
          wordpress_id
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 230) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    allWordpressPost(
      filter: { categories: { elemMatch: { wordpress_id: { eq: 57 } } } }
    ) {
      edges {
        node {
          wordpress_id
          title
          slug
          excerpt
          content
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 540) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
